const COUNTRIES =  Object.freeze({
  "GB": "United Kingdom",
  "IE": "Ireland",
  "DZ": "Algeria",
  "ID": "Indonesia",
  "AE": "United Arab Emirates",
  "MY": "Malaysia",
  "MA": "Morocco",
  "SE": "Sweden",
  "BE": "Belgium",
  "SG": "Singapore",
  "DK": "Denmark",
  "NO": "Norway",
  "TH": "Thailand",
  "OM": "Oman",
  "FR": "France",
  "SA": "Saudi Arabia",
  "AT": "Austria",
  "US": "United States",
  "BN": "Brunei",
  "NZ": "New Zealand",
  "JO": "Jordan",
  "TR": "Turkey",
  "PL": "Poland",
  "RU": "Russia",
  "MD": "Moldova",
  "DE": "Germany",
  "PT": "Portugal",
  "LK": "Sri Lanka",
  "TN": "Tunisia",
  "QA": "Qatar",
  "CA": "Canada",
  "IT": "Italy",
  "ES": "Spain",
  "PK": "Pakistan",
});

const PRECALC_FILES = Object.freeze({
  GB: {
    1: '1wi_U0csPK9lcKdnBwewoZU5urQHOUpOgA51k7KbUkYk',
  },
  IE: {
    1: '1YPhddu9fWtcWJfvdkmx0meGnH9Yc5Jv0bynXPW5WTow',
  },
  DZ: {
    1: '1_uLp02FjT_DHfULFmxd_xj78h3GAWm6fDkXZ1WwPBd0',
  },
  ID: {
    1: '1AWLP2XNKuSCsNgDQRpPLNIgTLrN2vZP51cin6G8G3Ec',
    2: '1hIABDljUScW1B4eqBfy6i5BFcJV6eiWlzFW9fYkNS-A',
    3: '1wDiWaWClomITgjJnVc_jorakWmGswJ7YlrI3_tMC0XM',
    4: '14Tl2bsIX_5rsSiG19kJZp8RiKpeB7zDC2ckEcwguOEg',
    5: '1gIPg5WZ248nmXx_7X2-4LuosArae8xxdqGBe9DKWG_0',
  },
  AE: {
    1: '1RMvfhLvD_cXa6qva_qLrLT7-wZgbSZHMhMUlkUNG10A',
  },
  MY: {
    1: '1_ZbpWWSyiTeVJ9MNNZOqr84gxB2eaeSGnkHzUe8NT2U',
    2: '1lRVyz5cKIfQdAB0K7FFxPDGm6Y8gytye0vusNpgFxzw',
    3: '1QYbJKgX0Ep1lf2CNXes79Co26G9GC7Pju4clBSBl0zI',
  },
  MA: {
    1: '19_ZbSKANs8ol7iRUyRHaiDjlOxqJCLGIN_JLIBHb8fI',
  },
  SE: {
    1: '1HF_MG91jn4VsxTO4FsLoS0FOkRjrz_XkOuK04V08-QM',
  },
  BE: {
    1: '18LdN6EzpJxb8CJEWFLQeLq6_Nizy9piH-GAgm2s7vaA',
  },
  SG: {
    1: '13GOipmfcNTU59i9QaZd6tAQcqAOOw61O4fci1QxNTqw',
  },
  DK: {
    1: '1-RZ475Puo_RLsN96-CJVzJNpqpFUzVoJg8g3GNj_yUM',
  },
  NO: {
    1: '1ZxxBfREX8eNFLAHp_hSQfH62LcRugVpthkhHt1JspAg',
  },
  TH: {
    1: '1U9M6Lo6Je_vP39FO_CTkK_uXm_eLzwS3OR4GJA33_zE',
  },
  OM: {
    1: '1MEyNu95gIvQSsAc7t0wJWwUkcehvJCYhYYg47NHcJ20',
  },
  FR: {
    1: '1jGoLRn4pBskTuVvcH4kXhJvaPaHjbp8wLxPO5gEVN4g',
  },
  SA: {
    1: '1Rz2snO-iLgF6tmbugSkwISoDVq9dWB2szPy2_qEdpt8',
  },
  AT: {
    1: '1b1pqtRZnO9JXLmitn-c3RuKpKscpfIvuv1XYCZyVPw4',
  },
  US: {
    1: '1edYPv7aMvJ_TVPnb7m83483rBYSifff4XXWs7SwVhkY',
  },
  BN: {
    1: '1BOPL1SHjNCosAEAe9jkppGJ18yyp-rRagv7avJTAAuw',
  },
  NZ: {
    1: '1MZ9eYUvGYDIF4f7L33U8Jk5bY6goNpTwsxiZfQCs8A8',
  },
  JO: {
    1: '1Wy86ubnVcAkoLYf3tGvr8MYIFzrNCxJrDf6LtggdeB8',
  },
  TR: {
    1: '1e3rjnr08U7TxzGgx_eUMfjZwRvBayCv_yHwYUL3aOs0',
    2: '1eSwcHXZ8Pr-DeNGy_G7juMUPNlpXYUZFG23Vg8HyLyo',
    3: '1GLSFqQQjVWqpKmLC0cDS1Cqz0lPWo9wN-bhtAv9GRL4',
  },
  PL: {
    1: '1vMOIh7q8oiwocbOuJ1jPMQceRVxzahph0B0WRxT7bb4',
  },
  RU: {
    1: '14XDh8FaKw7K77ch6h-AjKQnpSzrtzrKKHZfE1dk9JN0',
    2: '1ukKCeW-pklTcStCpmBpZcfbnwmRIiy8jyX5qJA6M26w',
  },
  MD: {
    1: '1PbBZWuqurSuJEa-XtE5NSrnyXbJ-9gTqmQV49u_gt8c',
  },
  DE: {
    1: '1YZ9GLxGbiyw11gjjs0Y8tQlTsbQpkOGNMvDaUWfpT9w',
    2: '1ZNgWlDRnB4jyZJK_ly8AveXiAeqf1HaKnuHYKWgWoMk',
    3: '1hmfBxq9N840cjOBBAUh_T7IbtCmT3hn_iTY8nUYPknI',
  },
  PT: {
    1: '1XMjqG53GD7cUa1EsegkYRoYeTO-4vIW5aPPn1uufeZA',
  },
  LK: {
    1: '14Q4ggrHv2GQeau-hz4dmDNMu7ViOQjec5saueK4y8YI',
  },
  TN: {
    1: '1pPm5Qgh31GvDzHZWsHDAjVHvvvBlQUIriho4_RkQyoY',
  },
  QA: {
    1: '1ubbl9hmnDJ2mKugDr1R2gIQh6NCZppzS7ZQGpP9H4s4',
  },
  CA: {
    1: '1l0yHylNjW-BOQFVxr68prs_xeH0Opm62ofCFvOoL6zY',
  },
  IT: {
    1: '1H5P_437tt_sQORr72MQlUsnhmSNlD2rhXGRy9F7LsHk',
  },
  ES: {
    1: '1sPsBv7nyEI1mrBMr8-5duU7G5MH0UkNtLYGVyHTy5cc',
  },
  PK: {
    1: '15QvdYrCs4MYevHHJaGVJIN6Ifu3pXVCat0bI6hEyiO4',
  }
});

const PRECALC_OPERATIONS = Object.freeze({
  Validate: 'Validate',
  Push: 'Push',
});

const PRECALC_LIST_OPERATIONS = Object.freeze({
  UpdateWithDiffer: 'UpdateWithDiffer',
  UpdateRawFile: 'UpdateRawFile',
});

const SHEET_NUMBER_TYPES = Object.freeze({
  All: 'All',
  Range: 'Range',
  Custom: 'Custom',
});

const CACHE_FILE_TYPES = Object.freeze({
  SETTINGS_API: 'SETTINGS API',
  PRECALC_LIST: 'PRECALC LIST',
  PRECALC_FILE: 'PRECALC FILE',
});

const GOOGLE_SHEETS_BASE_URL = 'https://docs.google.com/spreadsheets/d/';

const ERROR_LIST_TABLE_HEADERS = [{
  dataField: 'id',
  text: '#'
}, {
  dataField: 'sheetTitle',
  text: 'Sheet Name'
}, {
  dataField: 'warningsCount',
  text: 'Warnings Count'
}, {
  dataField: 'errorsCount',
  text: 'Errors Count'
}];

const SUCCESS_LIST_TABLE_HEADERS = [{
  dataField: 'id',
  text: '#'
}, {
  dataField: 'sheetTitle',
  text: 'Sheet Name'
}, {
  dataField: 'warningsCount',
  text: 'Warnings Count'
}];

const fileNameRegex = /^[A-Za-z]{2}\d+$/;

const HEADERS_WITH_SUNRISE = ['Fajr', 'Sunrise', 'Duhr', 'Asr', 'Maghrib', 'Isha'];
const HEADERS_WITHOUT_SUNRISE = ['Fajr', 'Duhr', 'Asr', 'Maghrib', 'Isha'];

export {
  GOOGLE_SHEETS_BASE_URL,
  PRECALC_OPERATIONS,
  PRECALC_LIST_OPERATIONS,
  SHEET_NUMBER_TYPES,
  PRECALC_FILES,
  COUNTRIES,
  ERROR_LIST_TABLE_HEADERS,
  SUCCESS_LIST_TABLE_HEADERS,
  fileNameRegex,
  HEADERS_WITH_SUNRISE,
  HEADERS_WITHOUT_SUNRISE,
  CACHE_FILE_TYPES,
};
